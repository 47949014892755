const ChiefCroppedIcon = () => {
        return (
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                        width="100%" height="100%" viewBox="0 0 510.000000 387.000000"
                        preserveAspectRatio="xMidYMid meet">
                        <g transform="translate(0.000000,387.000000) scale(0.100000,-0.100000)"
                                fill="currentColor" stroke="none">
                                <path d="M1935 3780 c-282 -29 -757 -222 -961 -390 -163 -135 -909 -987 -902
-1031 3 -20 5 -19 44 9 94 68 405 155 760 211 115 19 167 34 400 121 229 85
273 105 297 132 15 18 27 39 27 48 1 70 121 205 232 259 180 88 364 92 477 11
36 -27 101 -110 101 -131 0 -3 -14 -9 -31 -13 -26 -5 -36 -1 -59 24 -58 62
-152 27 -155 -57 l-1 -38 -151 -43 -151 -43 -31 32 c-28 27 -37 31 -69 26 -47
-8 -82 -47 -82 -92 0 -32 -3 -35 -57 -55 -43 -16 -76 -39 -128 -90 -68 -66
-73 -69 -203 -114 -153 -53 -262 -81 -347 -91 -84 -9 -288 -50 -426 -85 -140
-35 -234 -68 -358 -126 -72 -34 -92 -47 -87 -60 3 -8 6 -20 6 -25 0 -16 49
-10 228 31 233 54 215 47 207 78 -4 15 -11 29 -16 30 -5 2 -9 8 -9 12 0 18 42
31 127 41 56 6 122 23 183 46 68 26 122 38 190 45 70 7 134 22 247 60 l153 52
50 -24 51 -24 97 25 c53 13 102 25 108 27 8 2 20 -42 35 -123 19 -104 29 -135
56 -176 18 -28 33 -53 33 -56 0 -3 -11 -18 -25 -35 -24 -28 -35 -76 -15 -63 5
3 18 2 27 -3 10 -6 26 -6 40 -1 19 7 23 5 23 -11 0 -11 -4 -20 -9 -20 -9 0
-180 -113 -301 -200 -126 -90 -587 -506 -606 -547 -11 -26 -24 -21 -24 10 0
16 -3 17 -15 7 -23 -19 -19 -85 5 -101 20 -14 21 -16 5 -77 l-16 -62 35 -42
c20 -23 47 -49 60 -57 13 -9 51 -50 84 -91 33 -41 92 -105 131 -142 l70 -67
48 16 c26 9 60 25 75 37 l28 20 25 -24 24 -23 66 41 c36 23 65 40 65 38 0 -2
-6 -19 -14 -37 -16 -38 -291 -317 -302 -306 -4 3 -18 0 -33 -7 -23 -12 -39
-10 -125 10 -54 14 -100 23 -101 22 -1 -2 -12 -18 -24 -35 l-22 -33 28 -11
c128 -50 135 -51 160 -40 66 30 60 22 -141 -204 -81 -91 -146 -169 -142 -172
3 -3 49 34 101 83 282 262 340 313 450 402 124 101 215 167 215 157 0 -3 -11
-28 -25 -55 -14 -27 -25 -56 -25 -63 0 -8 -46 -52 -103 -98 -139 -114 -436
-371 -466 -403 l-24 -26 209 0 210 0 70 59 c39 32 99 73 135 91 35 18 79 46
97 61 18 16 39 29 46 29 8 0 26 14 40 31 36 42 34 84 -5 137 -31 42 -31 44
-24 125 l6 82 -31 23 c-32 24 -39 23 -79 -12 l-25 -21 22 31 c54 78 188 174
243 174 23 0 91 -59 83 -71 -3 -5 -21 4 -40 21 -34 29 -35 30 -71 15 -64 -27
-68 -34 -33 -70 28 -29 30 -36 30 -105 0 -41 -5 -95 -11 -120 -14 -61 7 -310
27 -326 17 -13 122 -29 132 -20 3 3 7 31 10 61 4 51 8 58 52 95 39 33 49 48
59 92 6 29 14 62 17 73 3 14 -7 36 -31 68 l-37 48 17 77 c9 42 21 81 26 86 12
13 12 13 -10 -73 -21 -80 -20 -86 24 -148 35 -48 38 -50 168 -59 l98 -7 -6 26
c-6 21 0 29 39 56 25 18 46 39 46 48 0 9 5 45 11 80 l11 64 32 -7 c17 -3 33
-8 35 -10 2 -2 -4 -42 -14 -89 l-18 -85 -59 -36 c-72 -44 -84 -62 -92 -139 -6
-58 -5 -62 24 -90 26 -25 30 -35 25 -62 -5 -26 -2 -34 15 -43 13 -7 19 -18 16
-28 -3 -9 -8 -30 -12 -46 l-7 -29 57 5 c68 6 86 20 217 185 87 108 92 112 136
119 37 5 69 0 154 -25 166 -48 161 -36 -11 25 -164 59 -193 74 -144 74 60 0
73 10 89 63 29 97 27 101 -72 132 -49 15 -92 28 -98 30 -5 1 41 18 102 39 142
45 153 43 334 -60 176 -101 245 -148 423 -293 83 -66 151 -119 153 -117 2 2
22 42 46 89 39 80 47 90 112 134 38 27 71 55 73 63 3 17 -154 247 -180 264
-12 7 -31 6 -70 -7 -45 -14 -54 -15 -61 -2 -6 12 -16 11 -64 -11 l-57 -24
-103 50 c-58 28 -152 67 -211 87 -90 30 -120 36 -192 36 -47 -1 -114 -8 -150
-18 -65 -17 -171 -65 -200 -91 -9 -7 -45 -20 -80 -28 -76 -16 -260 -30 -260
-19 0 5 106 61 236 126 129 65 234 121 232 125 -51 109 -143 273 -163 290
l-27 23 -55 -28 c-31 -15 -74 -46 -97 -67 -36 -34 -237 -146 -262 -146 -9 0
-44 77 -44 95 1 6 24 21 52 35 67 33 133 93 235 215 l84 100 251 147 c138 81
253 146 256 144 7 -8 -29 -73 -97 -172 -67 -98 -69 -100 -184 -169 -64 -38
-116 -74 -117 -79 0 -5 12 -24 26 -43 14 -18 36 -58 49 -88 21 -46 31 -58 65
-71 43 -18 46 -24 26 -69 -11 -26 -11 -35 5 -67 l18 -36 78 5 c74 4 102 -1
523 -106 245 -62 467 -119 493 -127 27 -9 50 -14 52 -12 3 2 -22 63 -54 134
-32 71 -118 262 -191 424 -73 162 -154 342 -180 400 -27 58 -47 107 -45 108
11 11 44 -13 58 -43 19 -41 29 -42 115 -15 73 23 121 25 144 8 48 -40 63 -111
29 -142 -10 -9 -52 -33 -94 -54 l-76 -37 25 -55 c14 -30 109 -237 211 -460
l186 -405 105 -138 c57 -75 112 -141 121 -146 9 -5 77 -23 151 -41 l135 -33
133 67 c72 36 132 68 132 71 0 2 18 82 40 176 22 95 40 179 40 188 0 9 -125
364 -278 790 l-278 774 -104 58 -105 58 -3 -39 c-3 -36 -6 -40 -40 -49 -63
-17 -166 -41 -178 -41 -6 0 -23 23 -39 51 -27 50 -29 51 -63 45 -39 -6 -76
-38 -175 -151 -67 -76 -61 -73 54 28 42 37 79 67 82 67 3 0 11 -16 17 -35 l11
-35 -132 -147 c-73 -82 -136 -149 -141 -151 -4 -2 -8 3 -8 12 0 22 -50 45 -76
36 -20 -8 -84 -118 -84 -145 0 -29 -98 -34 -121 -7 -14 16 -38 20 -164 25
-144 6 -148 6 -190 -18 -52 -31 -53 -31 -65 0 -28 72 -31 68 92 120 62 26 149
66 193 89 72 37 77 41 51 44 -34 4 -79 -6 -236 -53 -63 -19 -147 -44 -187 -56
l-72 -21 24 -49 c20 -39 30 -49 50 -49 18 0 28 -8 35 -26 21 -53 18 -56 -182
-169 -204 -116 -195 -108 -348 -287 -32 -37 -33 -40 -12 -34 23 8 23 8 7 -18
-10 -14 -51 -44 -91 -67 -41 -22 -82 -51 -90 -64 -13 -21 -13 -27 1 -61 8 -21
13 -44 11 -50 -3 -7 -1 -26 3 -42 8 -28 6 -30 -45 -55 l-53 -26 -31 44 c-17
25 -38 45 -46 45 -61 0 -226 -107 -298 -193 -29 -35 -38 -40 -53 -31 -14 9
-23 4 -50 -23 -33 -32 -85 -63 -132 -78 -24 -7 -24 -7 -18 45 5 43 4 50 -7 41
-8 -7 -17 -23 -20 -36 -7 -28 -77 -79 -138 -101 l-43 -15 -53 57 c-38 40 -50
60 -43 67 19 19 12 56 -15 73 -19 13 -28 14 -37 5 -20 -20 -41 -12 -71 25 -21
26 -25 39 -17 47 17 17 13 45 -9 67 -17 17 -24 18 -45 8 -23 -10 -29 -9 -51
13 -24 25 -24 25 -8 74 9 28 21 54 25 60 6 6 35 -17 77 -58 47 -46 71 -64 81
-58 15 10 5 36 -28 65 -27 24 -26 30 3 44 49 22 89 5 166 -73 l72 -73 -27 -61
c-15 -33 -31 -61 -36 -61 -5 0 -6 7 -2 16 4 11 2 15 -7 12 -8 -2 -16 -10 -18
-16 -5 -17 51 -70 83 -78 24 -6 33 -2 59 28 129 148 167 185 226 225 84 55
150 133 150 177 1 44 26 71 90 96 75 29 229 111 317 168 80 52 83 60 58 132
-18 50 -15 56 27 65 18 4 51 16 75 26 l43 18 -6 48 c-4 26 -22 93 -42 148 -32
92 -50 163 -66 255 -3 19 -4 31 -1 25 14 -30 115 -374 115 -393 0 -26 17 -62
29 -62 4 0 16 18 26 41 13 29 42 58 95 99 41 31 83 67 94 78 10 12 23 19 27
16 14 -8 10 -52 -5 -61 -24 -14 -166 -196 -165 -213 0 -13 1 -13 7 1 4 9 40
57 80 106 73 90 74 91 135 103 34 6 130 33 212 60 217 71 318 98 386 106 47 5
59 10 55 21 -4 8 -6 23 -6 34 0 23 -31 25 -112 8 -67 -14 -95 -29 -153 -79
-59 -51 -73 -52 -18 -1 92 86 166 121 255 121 36 0 38 1 38 33 0 19 -9 60 -21
93 -11 32 -27 92 -34 132 -8 40 -21 82 -30 94 -23 34 -19 44 29 78 76 55 76
55 12 254 l-56 175 -30 -6 c-40 -7 -80 19 -78 51 5 59 -146 254 -255 328 -69
47 -316 163 -427 201 -154 52 -364 75 -535 57z m3 -182 c12 -62 12 -70 -5 -92
-10 -14 -34 -31 -53 -37 -84 -28 -254 -126 -381 -220 l-80 -60 -101 83 c-56
46 -103 88 -105 94 -4 13 65 54 234 138 183 91 397 168 453 163 22 -2 26 -9
38 -69z m922 -358 c0 -6 -409 -155 -425 -155 -5 0 -11 7 -13 16 -2 12 41 31
188 84 105 37 193 72 196 77 3 5 -27 46 -67 91 l-72 82 96 -95 c53 -52 97 -97
97 -100z m-1577 40 c48 -39 94 -82 103 -96 17 -25 16 -26 -22 -66 -21 -22 -64
-61 -94 -87 -55 -46 -59 -47 -212 -79 -151 -32 -157 -32 -197 -16 -23 9 -41
18 -41 21 0 3 24 26 52 51 29 26 111 101 181 166 118 109 128 122 123 148 -4
19 -1 28 7 28 7 0 52 -32 100 -70z m1994 -314 c29 -83 51 -151 50 -152 -9 -7
-218 -134 -237 -144 -17 -8 -10 3 23 37 26 27 47 55 47 62 0 7 -38 57 -83 110
-83 94 -117 155 -98 174 13 13 201 66 225 64 16 -2 30 -31 73 -151z m-567 -6
c0 -24 -12 -30 -134 -65 -89 -26 -107 -35 -171 -90 -65 -55 -83 -64 -180 -94
-60 -18 -111 -29 -116 -24 -4 4 -11 34 -15 65 l-7 57 89 27 c222 68 520 151
527 147 4 -2 7 -13 7 -23z m230 -7 c22 -20 40 -42 40 -48 0 -16 37 -48 48 -41
5 3 28 -15 52 -39 l44 -45 -57 -50 c-80 -72 -224 -242 -282 -333 -39 -63 -51
-75 -56 -60 -16 38 -8 71 26 116 19 25 35 52 35 59 0 8 -11 52 -25 99 l-25 84
40 38 c53 51 72 120 47 173 -22 46 -22 51 11 68 41 23 59 19 102 -21z m-170
-65 c16 -56 6 -98 -33 -142 -30 -34 -46 -40 -287 -111 -140 -41 -267 -79 -282
-85 -32 -10 -34 -8 -14 13 12 11 12 22 4 56 -6 23 -8 44 -6 47 3 2 47 16 99
30 51 14 100 31 109 38 134 114 151 126 217 145 37 11 91 26 118 35 28 8 53
15 57 15 4 1 12 -18 18 -41z m-955 -23 c31 -30 32 -62 3 -92 -27 -29 -65 -29
-95 -1 -30 28 -29 63 2 93 31 32 59 32 90 0z m234 -137 c1 -34 21 -68 41 -68
5 0 14 -18 20 -40 10 -35 8 -44 -9 -66 -18 -22 -18 -29 -7 -62 12 -35 16 -37
62 -42 46 -5 48 -6 26 -18 -21 -12 -21 -14 -6 -44 9 -16 25 -33 37 -36 17 -4
12 -9 -28 -23 -68 -24 -78 -23 -70 1 4 12 1 27 -5 35 -14 17 -76 20 -85 5 -4
-7 2 -9 18 -5 26 7 41 -12 31 -40 -7 -17 -136 -80 -204 -100 -22 -6 -21 -4 12
27 31 29 42 34 73 30 31 -4 37 -1 42 17 4 17 12 21 32 19 19 -2 26 2 26 15 0
9 -4 16 -10 15 -5 -2 -9 -7 -7 -12 2 -5 -3 -4 -10 1 -14 12 -48 -5 -48 -23 0
-6 -9 -10 -20 -10 -29 0 -25 9 20 45 49 39 50 52 15 247 -32 180 -32 179 8
191 37 11 45 1 46 -59z m-79 -89 c23 -135 23 -131 4 -99 -14 24 -14 24 -7 -15
4 -22 7 -50 6 -63 -1 -18 2 -21 13 -11 12 9 14 5 14 -28 0 -34 -7 -46 -65
-103 l-64 -64 -11 28 c-5 15 -10 30 -10 32 0 3 26 33 57 67 l57 62 -71 -69
c-62 -60 -73 -67 -91 -57 -14 7 -24 28 -31 63 -28 145 -43 239 -38 252 3 7 26
21 52 29 46 16 47 16 96 -11 36 -20 51 -35 56 -54 6 -29 33 -39 33 -14 0 38
-22 64 -80 95 -34 18 -60 35 -57 37 6 6 111 42 115 39 1 -1 11 -54 22 -116z
m-240 34 c0 -5 -7 -17 -15 -27 -8 -11 -15 -28 -15 -37 0 -14 -22 -23 -106 -43
-99 -23 -109 -24 -136 -10 -17 9 -28 17 -26 19 4 5 280 103 291 104 4 1 7 -2
7 -6z m918 -21 c-1 -5 -41 -40 -88 -77 -47 -38 -131 -106 -186 -152 -109 -88
-109 -88 -134 -23 -16 43 -36 60 -71 60 -21 0 -28 6 -33 26 -4 15 -6 28 -4 29
4 4 500 144 511 144 5 1 7 -3 5 -7z m76 -95 c22 -59 21 -68 -19 -107 -29 -28
-37 -31 -50 -20 -21 17 -39 83 -31 114 6 24 46 55 72 56 6 0 19 -19 28 -43z
m248 -349 l3 -48 65 50 c92 72 131 97 160 104 16 4 10 -1 -15 -15 -22 -12 -53
-33 -70 -48 -61 -56 -211 -158 -255 -174 -60 -22 -162 -39 -172 -29 -4 4 -9
23 -10 42 -1 30 18 65 138 240 l139 205 7 -140 c4 -77 8 -161 10 -187z m-288
95 l16 -46 -48 -56 c-67 -78 -233 -255 -243 -259 -4 -2 -10 7 -13 20 -20 96
-25 131 -19 141 12 21 272 246 285 247 3 0 13 -21 22 -47z m1325 0 c-59 -65
-185 -209 -198 -225 -11 -15 -6 -30 35 -107 41 -76 55 -93 96 -117 26 -16 48
-34 48 -41 0 -18 -2 -17 -57 17 -41 26 -55 44 -98 126 l-50 95 -84 -86 c-46
-47 -86 -83 -88 -81 -8 8 396 445 411 445 6 1 -1 -11 -15 -26z m84 -55 c15
-12 9 -22 -55 -93 -39 -44 -93 -105 -120 -134 -46 -53 -49 -54 -63 -35 -8 10
-15 26 -15 34 0 13 217 240 230 240 4 0 14 -6 23 -12z m-1832 -40 c7 -13 13
-50 14 -82 1 -48 6 -62 24 -77 28 -23 48 -78 32 -88 -6 -4 -74 -29 -151 -55
l-140 -48 0 -50 c0 -46 -2 -49 -22 -46 -20 3 -24 11 -29 58 -4 30 -23 110 -44
178 -20 68 -34 126 -30 130 8 9 294 99 317 101 10 1 23 -9 29 -21z m2194 -206
c105 -76 192 -147 193 -156 9 -42 -19 -29 -111 53 l-98 87 -205 -71 -204 -72
-52 24 c-30 14 -63 38 -76 56 l-22 32 137 131 138 132 55 -39 c30 -22 141
-101 245 -177z m-2561 -56 c24 -48 26 -105 6 -117 -44 -25 -120 -56 -120 -49
0 5 9 12 20 15 25 8 25 37 2 85 -13 27 -23 34 -40 32 -23 -3 -30 20 -13 46 7
11 9 10 15 -3 5 -14 11 -13 48 9 24 14 48 24 53 23 6 -2 19 -20 29 -41z m430
-62 c4 -14 4 -28 2 -33 -6 -9 -254 -86 -260 -80 -2 2 -7 18 -10 34 l-7 30 123
41 c68 23 128 39 135 37 6 -3 14 -16 17 -29z m2160 -38 c63 -63 103 -126 141
-228 12 -33 25 -50 45 -58 22 -9 34 -26 54 -82 15 -39 25 -73 22 -75 -2 -2
-20 23 -40 55 -30 49 -42 61 -69 66 -32 6 -37 13 -92 131 -61 131 -117 201
-153 191 -9 -2 -77 -23 -149 -45 -137 -43 -153 -54 -153 -104 0 -22 -16 -37
-39 -37 -7 0 12 127 20 134 5 4 331 114 341 115 4 1 36 -28 72 -63z m-75 -55
c31 -57 77 -209 70 -229 -6 -17 -35 -30 -139 -62 -72 -23 -133 -40 -135 -38
-5 6 -73 192 -80 219 -4 17 -2 31 7 39 12 11 229 87 255 89 7 1 17 -7 22 -18z
m-2429 -21 c0 -16 5 -30 11 -30 19 0 -1 -18 -28 -25 -38 -9 -268 -111 -323
-142 -59 -35 -218 -179 -238 -218 -8 -16 -30 -74 -47 -128 -35 -105 -41 -118
-50 -95 -4 9 15 80 40 158 42 128 52 147 93 191 73 76 136 150 170 200 36 53
111 103 139 92 10 -3 28 -17 40 -30 l23 -23 77 39 c43 22 81 40 86 41 4 0 7
-13 7 -30z m-304 -358 c10 -20 20 -60 22 -89 4 -46 1 -55 -28 -91 -18 -22 -77
-82 -132 -133 l-99 -92 -45 48 c-29 31 -44 57 -44 74 0 14 14 69 31 121 31 94
33 96 123 183 l91 89 32 -37 c17 -20 39 -53 49 -73z m2940 -237 c59 -148 118
-284 131 -302 16 -21 28 -55 34 -99 5 -37 8 -69 6 -71 -2 -2 -15 6 -30 17 -20
16 -28 35 -38 93 -8 44 -50 163 -110 312 -53 132 -99 260 -103 285 -4 24 -5
42 -2 40 2 -3 53 -126 112 -275z m-967 214 c120 -22 141 -33 141 -76 0 -29 -5
-29 -142 2 -147 33 -145 33 -158 -17 -6 -24 -15 -59 -21 -78 -11 -42 -12 -87
-1 -93 4 -3 90 -19 192 -36 102 -17 197 -34 212 -37 20 -4 36 -20 59 -60 17
-30 30 -57 28 -58 -2 -2 -119 21 -259 51 -278 60 -300 69 -300 128 0 52 50
258 67 277 21 23 41 23 182 -3z m827 -34 l1 -44 -75 -26 c-79 -26 -91 -24 -92
17 0 9 -5 20 -11 24 -18 11 2 22 91 48 47 14 85 25 85 25 0 1 0 -19 1 -44z
m-131 -324 c45 -155 85 -273 107 -315 34 -62 36 -78 22 -125 -3 -9 -20 11 -44
50 -30 48 -39 75 -40 108 0 25 -35 160 -80 312 -44 148 -80 272 -80 276 0 5
10 -7 22 -25 11 -19 53 -145 93 -281z m-571 223 c-1 -5 -9 -42 -19 -82 -14
-58 -21 -72 -34 -68 -9 3 -59 12 -111 22 -124 21 -128 25 -106 106 9 35 19 68
21 74 4 13 251 -38 249 -52z m827 -204 c54 -129 99 -240 99 -245 0 -10 -228
-91 -234 -83 -4 4 -134 469 -138 492 -2 16 11 24 77 47 44 15 84 26 88 25 5
-1 53 -107 108 -236z m-2074 -156 c-13 -13 -150 -62 -162 -58 -12 5 -75 139
-75 159 0 16 34 37 92 60 l63 24 43 -91 c23 -50 41 -92 39 -94z m-408 91 l41
-66 -55 -35 c-76 -48 -77 -48 -109 -15 l-29 30 -58 -44 c-31 -25 -61 -45 -66
-45 -5 0 -58 -34 -118 -75 -102 -70 -145 -89 -145 -64 0 5 4 8 10 4 5 -3 117
66 250 155 175 117 240 165 240 180 0 10 -4 22 -10 25 -5 3 -10 11 -10 16 0
19 18 -1 59 -66z m275 -250 c76 -8 141 -16 143 -19 3 -3 -2 -36 -11 -74 -16
-64 -20 -70 -60 -92 -42 -23 -45 -23 -79 -7 -39 19 -102 23 -112 7 -12 -19
-34 -10 -54 24 -21 33 -21 35 -4 105 9 39 22 71 27 71 6 0 73 -7 150 -15z
m2458 -97 c40 -12 40 -12 34 -51 -4 -22 -11 -47 -15 -56 -5 -9 -59 -35 -119
-58 l-110 -42 -61 20 c-34 12 -61 24 -61 28 0 4 7 28 15 53 l16 45 112 36
c127 40 135 41 189 25z"/>
                                <path d="M1508 1393 c-70 -73 -76 -84 -102 -166 -29 -95 -28 -112 9 -147 l24
-23 68 61 c120 106 163 157 163 194 -1 50 -55 158 -80 158 -4 0 -40 -35 -82
-77z"/>
                                <path d="M1230 2474 c-206 -54 -343 -99 -348 -113 -5 -16 14 -14 96 10 45 14
72 27 72 36 0 8 5 11 10 8 6 -3 10 -15 10 -25 0 -13 -14 -23 -50 -35 l-50 -17
0 -107 c0 -106 0 -107 -30 -136 -23 -22 -39 -55 -62 -130 -16 -55 -37 -110
-44 -122 -9 -15 -10 -23 -4 -23 6 0 8 -5 5 -10 -4 -7 -16 -3 -33 8 -43 30 -47
31 -62 22 -11 -7 -1 -28 46 -99 47 -73 64 -91 83 -91 13 0 69 -12 123 -26 l98
-26 80 72 c43 39 81 79 84 88 2 9 7 31 11 49 5 25 44 65 196 199 176 156 191
172 204 218 14 45 13 56 -5 133 -30 128 -29 127 -111 120 -60 -5 -74 -3 -111
18 l-43 23 -165 -44z"/>
                                <path d="M781 2335 c-35 -14 -73 -25 -86 -25 -15 0 -33 -16 -60 -56 l-38 -55
27 -112 c44 -177 87 -256 126 -232 6 4 22 -1 36 -10 33 -21 40 -14 19 18 -15
23 -15 28 -2 49 8 13 18 39 22 58 12 56 52 138 74 152 19 11 21 23 21 100 l0
88 -30 0 c-27 0 -30 2 -24 25 8 31 -2 31 -85 0z"/>
                                <path d="M1494 1941 c-168 -136 -186 -153 -183 -180 4 -22 18 -12 161 109 87
73 155 137 152 142 -11 18 -36 5 -130 -71z"/>
                                <path d="M2977 673 c-32 -9 -56 -18 -53 -21 3 -3 34 -6 68 -7 35 -1 79 -9 98
-17 l35 -16 -45 -1 -45 -1 -19 -80 c-10 -44 -17 -82 -15 -84 2 -1 69 -37 149
-79 132 -69 161 -79 330 -123 161 -41 193 -46 243 -40 45 6 56 10 48 20 -6 7
-85 70 -175 139 -219 169 -484 329 -541 326 -11 -1 -46 -8 -78 -16z"/>
                        </g>
                </svg>


        )
}

export default ChiefCroppedIcon
